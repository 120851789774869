#partners_module {
	.homepage-content-box.partners-carousel {
		position: relative;
	}

	.cycle-slideshow-carousel {
		background-color: transparent;
		height: 80px;
		margin: 0 auto;
		position: relative;
		width: calc(100% - 70px);

		.partner-carousel-slide {
			position: relative;
			height: 80px;
			min-width: 140px;
			display: inline-block;
		}

		.partner-carousel-link {
			text-decoration: none;
			border: 0 none;
			background-color: transparent;
			box-shadow: none;
		}

		.partner-carousel-link:hover {
			text-decoration: none;
			border: 0 none;
			background-color: transparent;
			box-shadow: none;
		}

		.partner-carousel-image {
			max-height: 100%;
			border: 0 none;
			margin-left: auto;
			margin-right: auto;
			display: table-caption;
		}

		.partner-carousel-image.no-link {
			display: block;
			position: relative;
			top: 50%;
			transform: translate(0px, -50%);
		}
	}

	.partners-arrow-div {
		height: 100%;
		width: 30px;
		position: absolute;
		bottom: 0;
		top: 0;
		cursor: pointer;
	}

	.partners-cycle-carousel-previous {
		right: auto;
		left: 0;
	}

	.partners-cycle-carousel-next {
		right: 0;
		left: auto;
	}

	.partners-arrow {
		width: 20px;
		height: 20px;
		border: 2px solid;
		transform: rotate(45deg);
		position: absolute;
		top: 36%;
	}

	.arrow-icon-left {
		left: 10px;
		border-right: 0 none;
		border-top: 0 none;
	}

	.arrow-icon-right {
		left: 0;
		border-left: 0 none;
		border-bottom: 0 none;
	}
}
